import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import Info from '@/Shared/Info'
import Select, { components } from 'react-select'
import { usePage } from '@inertiajs/react'

export default React.forwardRef(
  (
    {
      async,
      autoFocus,
      creatable,
      classes,
      disabled,
      disabledMessage,
      error,
      height,
      hideErrorMessage,
      info,
      isClearable,
      isSearchable,
      label,
      minHeight,
      multiple,
      name,
      optionLabel,
      optionValue,
      options,
      optionStyle,
      placeholder,
      placement,
      required,
      value,
      onChange,
      onInputChanged,
      ...props
    },
    ref,
  ) => {
    const { user } = usePage().props.auth
    const primaryColor = user.type === 'Advisor' ? '#0170b7' : '#1c3f95'
    const primaryColor50 = user.type === 'Advisor' ? '#ebf7ff' : disabled ? '#d7dfff' : '#edf0fc'

    let customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? primaryColor : '#d1d5db',
        height: height || (state.isMulti ? '100%' : '2.75rem'),
        minHeight: minHeight || '38px',
        borderRadius: '0.25rem',
        boxShadow: error ? '0 0 0 0 #fff,0 0 0 2px rgb(239 68 68 / 1),var(--tw-shadow, 0 0 #0000)!important;' : '',
      }),
      group: (provided) => ({}),
      groupHeading: (provided) => ({}),
      indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
      }),
      input: (provided) => ({
        ...provided,
        boxShadow: 'none!important',
        marginLeft: '0.375rem',
      }),
      option: (styles, { isDisabled, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? primaryColor : undefined,
          color: isSelected ? '#ffffff' : undefined,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':hover': {
            ...styles[':hover'],
            backgroundColor: isSelected ? undefined : '#efefef',
          },
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? (isSelected ? primaryColor : primaryColor50) : undefined,
          },
        }
      },
      placeholder: (provided) => ({
        ...provided,
        color: '#9ca3af',
        marginLeft: '0.375rem',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginLeft: '0.375rem',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: primaryColor50,
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        paddingRight: disabled ? '6px' : '3px',
        fontWeight: disabled ? '500' : '400',
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        display: disabled ? 'none' : 'flex',
      }),
    }

    const [styles, setStyles] = useState(customStyles)
    const [defaultOptions, setDefaultOptions] = useState(null)
    const loadOptions =
      async &&
      React.useCallback(
        _.debounce((input, callback) => {
          onInputChanged(input).then((response) => callback(response.data))
        }, 250),
        [],
      )

    useEffect(() => setStyles({ ...customStyles, ...optionStyle }), [optionStyle])

    useEffect(() => {
      let copy = options || []

      if (value && (async || copy.length > 0)) {
        const currentOption = { label: value, value: value }
        copy = copy.concat(currentOption)
      }

      setDefaultOptions(copy)
    }, [value])

    useEffect(() => setStyles(customStyles), [error])

    const LimitedValueContainer = useCallback(({ children, hasValue, options, ...props }) => {
      if (!hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>
      }

      const [chips, otherChildren] = children

      return (
        <components.ValueContainer {...props}>
          {chips && chips.length > 2 ? (
            <div className="rounded-sm bg-primary-100 px-2 py-1.5 text-sm leading-none">{`${chips.length} items selected`}</div>
          ) : (
            chips
          )}

          {otherChildren}
        </components.ValueContainer>
      )
    }, [])

    const formatGroupLabel = (data) => (
      <div className="flex items-center gap-3 border-b border-gray-300 bg-blue-500 px-3 py-2 text-white">
        <i className="far fa-list-tree text-lg"></i>
        <span className="font-bold uppercase">
          <span>{data.label}</span>
          {data.recommended && <span className="text-red-500"> - Recommended</span>}
        </span>
      </div>
    )

    const getOptionValue = (option) => (optionValue ? optionValue(option) : option.value || option.id)
    const getOptionLabel = (option) => (optionLabel ? optionLabel(option) : option.label || option.name)

    const getSelectedValue = () => {
      if (Array.isArray(value)) {
        return value
      }

      // if (defaultOptions && defaultOptions.length > 0) {
      // return defaultOptions.find((r) => r.value === value || r.label === value) || null
      // }

      if (async && defaultOptions && defaultOptions.length > 0) {
        return defaultOptions.find((r) => r.value === value) || null
      } else if (options?.length > 0) {
        const result = options.find((o) => {
          if (o.options?.length > 0) {
            const childOption = o.options.find((so) => so.value === value || so.label === value)
            if (childOption) {
              return true // This signals to return the parent to satisfy `find`, but we'll return the child instead.
            }
          }
          return o.value === value || o.label === value
        })

        // If we found a parent with children, return the matching child; otherwise, return the parent
        if (result?.options?.length > 0) {
          return result.options.find((so) => so.value === value || so.label === value)
        }
        return result
      }

      return null
    }

    return (
      <div id={name} className={props.className == undefined && classes == undefined ? 'mb-4 space-y-1.5' : props.className || classes}>
        {label && (
          <label
            htmlFor={name}
            className={classNames('mb-0.5 block text-sm font-medium uppercase', error ? 'text-red-600' : 'text-gray-500')}
          >
            {label}
            {required && <span className="pl-1 text-red-600">*</span>}
          </label>
        )}
        {async && creatable ? (
          <AsyncCreatableSelect
            ref={ref}
            className="react-select-container w-full"
            classNamePrefix="react-select"
            closeMenuOnSelect={multiple ? false : true}
            components={{ ValueContainer: LimitedValueContainer }}
            formatGroupLabel={formatGroupLabel}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            hideSelectedOptions={false}
            isClearable={isClearable != undefined ? isClearable : true}
            isDisabled={disabled != undefined ? disabled : false}
            isMulti={multiple != undefined ? multiple : false}
            isSearchable={isSearchable != undefined ? isSearchable : true}
            loadOptions={loadOptions}
            menuPlacement={placement || 'auto'}
            noOptionsMessage={() => 'Begin typing...'}
            placeholder={placeholder || ''}
            styles={styles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: primaryColor,
                primary25: primaryColor50,
              },
            })}
            value={getSelectedValue()}
            onChange={onChange}
            cacheOptions={false}
            defaultOptions={defaultOptions}
            autoFocus={autoFocus}
          />
        ) : (
          <React.Fragment>
            {async ? (
              <AsyncSelect
                ref={ref}
                className="react-select-container w-full"
                classNamePrefix="react-select"
                closeMenuOnSelect={multiple ? false : true}
                formatGroupLabel={formatGroupLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                hideSelectedOptions={false}
                isClearable={isClearable != undefined ? isClearable : true}
                isDisabled={disabled != undefined ? disabled : false}
                isMulti={multiple != undefined ? multiple : false}
                isSearchable={isSearchable != undefined ? isSearchable : true}
                loadOptions={loadOptions}
                menuPlacement={placement || 'auto'}
                noOptionsMessage={() => 'Begin typing...'}
                placeholder={placeholder || ''}
                styles={styles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: primaryColor,
                    primary25: primaryColor50,
                  },
                })}
                value={getSelectedValue()}
                onChange={onChange}
                cacheOptions={false}
                defaultOptions={defaultOptions}
                autoFocus={autoFocus}
              />
            ) : creatable ? (
              <CreatableSelect
                ref={ref}
                className="react-select-container w-full"
                classNamePrefix="react-select"
                closeMenuOnSelect={multiple ? false : true}
                components={{ ValueContainer: LimitedValueContainer }}
                formatGroupLabel={formatGroupLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                hideSelectedOptions={false}
                isClearable={isClearable != undefined ? isClearable : true}
                isDisabled={disabled != undefined ? disabled : false}
                isMulti={multiple != undefined ? multiple : false}
                isSearchable={isSearchable != undefined ? isSearchable : true}
                menuPlacement={placement || 'auto'}
                options={options}
                placeholder={placeholder || ''}
                styles={styles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: primaryColor,
                    primary25: primaryColor50,
                  },
                })}
                value={getSelectedValue()}
                onChange={onChange}
                autoFocus={autoFocus}
              />
            ) : (
              <Select
                ref={ref}
                className="react-select-container w-full"
                classNamePrefix="react-select"
                closeMenuOnSelect={multiple ? false : true}
                components={{ ValueContainer: LimitedValueContainer }}
                formatGroupLabel={formatGroupLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                hideSelectedOptions={false}
                isClearable={isClearable != undefined ? isClearable : true}
                isDisabled={disabled != undefined ? disabled : false}
                isMulti={multiple != undefined ? multiple : false}
                isSearchable={isSearchable != undefined ? isSearchable : true}
                menuPlacement={placement || 'auto'}
                options={options}
                placeholder={placeholder || ''}
                styles={styles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: primaryColor,
                    primary25: primaryColor50,
                  },
                })}
                value={getSelectedValue()}
                onChange={onChange}
                autoFocus={autoFocus}
              />
            )}
          </React.Fragment>
        )}

        {disabled && disabledMessage && (
          <p className="flex items-center leading-tight text-blue-400">
            <i className="fas fa-info-circle mr-2 text-lg"></i>
            {disabledMessage}
          </p>
        )}

        {info && <Info text={info} />}

        {error && !hideErrorMessage && <div className="text-red-600">{error}</div>}
      </div>
    )
  },
)
