import React from 'react'
import classNames from 'classnames'

export default function DropdownItem({ children, colorClasses, onClick, ...props }) {
  const handleClick = (event) => {
    event.preventDefault()
    onClick && onClick(event)
  }

  return !props.disabled && props.href ? (
    <a
      className={classNames(
        'group flex w-full cursor-pointer items-center px-4 py-3 hover:bg-gray-200/60 focus:rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
        props.className,
        colorClasses,
      )}
      role="menuitem"
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  ) : onClick ? (
    <button
      role="button"
      className={classNames(
        'group flex w-full cursor-pointer items-center px-4 py-3',
        props.className,
        props.disabled ? 'text-gray-300' : colorClasses,
        props.disabled
          ? 'cursor-not-allowed'
          : 'hover:bg-gray-200/60 focus:rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
      )}
      onClick={(event) => (!props.disabled ? handleClick(event) : undefined)}
    >
      {children}
    </button>
  ) : (
    <div
      className={classNames(
        'group flex w-full cursor-pointer items-center px-4 py-3',
        props.className,
        props.disabled ? 'text-gray-300' : colorClasses,
        props.disabled
          ? 'cursor-not-allowed'
          : 'hover:bg-gray-200/60 focus:rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500',
      )}
    >
      {children}
    </div>
  )
}

DropdownItem.defaultProps = {
  colorClasses: 'text-gray-700 hover:text-gray-900',
}
