import { Fragment, useState } from 'react'
import AddContactDialog from './AddContactDialog'
import Avatar from '@/Shared/Avatar'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

export default function Contact({ contact: transactionContact, transaction, onPreview }) {
  const { roles: allRoles, auth } = usePage().props
  const { user } = auth
  const contact = transactionContact.contact
    ? { ...transactionContact.contact, id: transactionContact.id, contact_type: transactionContact.type, type: 'TransactionContact' }
    : transactionContact

  let roles = transactionContact.roles?.reduce((carry, role) => {
    if (!transactionContact.party_representing.find((pr) => role.name == pr)) {
      let match = allRoles.find((r) => r.value === (role.party_representing ? `${role.party_representing}-${role.name}` : role.name))
      return carry.concat(match ? { ...role, name: match.label } : role)
    }
  }, [])
  let primaryClient = transactionContact.roles?.find((r) => r.primary && r.name === transaction.type)
  let linked = transactionContact.is_owner || transactionContact.followers?.find((f) => f.owner_id == user.id)
  let industry = transactionContact.industry || transactionContact.contact?.industry || ''
  let title = transactionContact.title || transactionContact.contact?.title || ''
  let phone = transactionContact.phone || transactionContact.contact?.phone || ''
  let phone_formatted = transactionContact.phone_formatted || transactionContact.contact?.phone_formatted || ''
  let phone_type = transactionContact.phone_type || transactionContact.contact?.phone_type || ''
  let email = transactionContact.email || transactionContact.contact?.email || ''

  const [contactAdding, setContactAdding] = useState(null)
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const addContact = () => {
    setContactAdding(contact)
    setAddDialogOpen(true)
  }

  const getFullName = () => {
    let first = transactionContact.first_name || transactionContact.contact?.first_name || ''
    let nickname = transactionContact.nickname || transactionContact.contact?.nickname || ''
    let middle = transactionContact.middle_name || transactionContact.contact?.middle_name || ''
    let last = transactionContact.last_name || transactionContact.contact?.last_name || ''
    let suffix = transactionContact.suffix || transactionContact.contact?.suffix || ''
    return (nickname || first) + (middle && ` ${middle}`) + (last && ` ${last}`) + (suffix && ` ${suffix}`)
  }

  const closeAddDialog = () => {
    setAddDialogOpen(false)

    setTimeout(() => {
      setContactAdding(null)
    }, 300)
  }

  return (
    <div className="relative w-full rounded-md border border-gray-300 shadow">
      <div>
        <button
          className="flex w-full items-center justify-between gap-3 py-3 pl-4 pr-5 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          onClick={onPreview}
        >
          <div className="flex-1">
            <div className="flex items-center gap-3 text-left">
              <div className="flex flex-col justify-center gap-1">
                <Avatar contact={contact} height="h-10" width="w-10" textSize="text-xl" />

                {contact.contact_type !== 'Contact' && (
                  <div className="mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-purple-800">
                    <span>PRO</span>
                  </div>
                )}
              </div>

              <div className="flex-1">
                <div className="space-y-0.5 leading-tight">
                  <div className={classNames('font-semibold', { 'text-blue-500': primaryClient })}>{getFullName()}</div>

                  {(industry || title) && (
                    <span className="inline-block text-sm leading-snug">
                      {contact.title !== 'Controller' && industry ? `${industry}` + (title ? ' | ' : '') : ''} {title}
                    </span>
                  )}
                  {contact.pending && <div className="text-sm">Invited {contact.updated_at}</div>}

                  {roles?.length > 0 && (
                    <div className="flex flex-wrap gap-1">
                      {roles.map((role, index) => (
                        <Fragment key={index}>
                          <span
                            className={classNames(
                              'inline-block rounded px-1.5 py-1 text-sm leading-none',
                              role.primary ? 'bg-blue-200 bg-opacity-40 font-medium text-blue-700' : 'bg-gray-200 text-gray-800',
                            )}
                          >
                            <span className="whitespace-pre-line leading-snug">{role.name}</span>
                          </span>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-shrink-0">
            <i className="fal fa-angle-right text-2xl leading-none"></i>
          </div>
        </button>

        {!contact.pending && (
          <div className="flex items-center justify-between gap-3 rounded-b-md border-t border-gray-200 px-2">
            <div className="flex items-center">
              <Tooltip label={linked ? 'In Contacts' : 'Add to Contacts'} placement="bottom">
                <button
                  className={classNames(
                    'group inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none',
                    linked ? 'cursor-not-allowed' : 'group transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                  )}
                  onClick={() => !linked && addContact()}
                >
                  <i
                    className={classNames(
                      'fas text-xl leading-none group-hover:font-bold',
                      linked ? 'fa-user-check text-green-700 opacity-75' : 'fa-user-plus text-blue-500',
                    )}
                  ></i>
                </button>
              </Tooltip>

              <Tooltip label={phone ? `Call ${phone_formatted}` : null} placement="bottom">
                <button
                  className={classNames(
                    'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                    phone ? 'group' : 'cursor-not-allowed opacity-30',
                  )}
                  onClick={() => (window.location.href = `tel:${phone}`)}
                  disabled={!phone}
                >
                  <i className="far fa-mobile text-xl leading-none group-hover:font-bold"></i>
                </button>
              </Tooltip>

              <Tooltip label={email ? `Email ${email}` : null} placement="bottom">
                <a
                  role="button"
                  className={classNames(
                    'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                    email ? 'group' : 'cursor-not-allowed opacity-30',
                  )}
                  href={email ? `mailto:${email}` : null}
                  disabled={!email}
                  target={email ? '_blank' : null}
                >
                  <i className="far fa-envelope text-xl leading-none group-hover:font-bold"></i>
                </a>
              </Tooltip>

              <Tooltip label={phone && phone_type.toLowerCase() === 'mobile' ? `Text ${phone_formatted}` : null} placement="bottom">
                <button
                  className={classNames(
                    'inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500',
                    phone && phone_type.toLowerCase() === 'mobile' ? 'group' : 'cursor-not-allowed opacity-30',
                  )}
                  onClick={() => (window.location.href = `sms:${phone}`)}
                  disabled={!phone || phone_type.toLowerCase() !== 'mobile'}
                >
                  <i className="far fa-comment-alt-dots text-xl leading-none group-hover:font-bold"></i>
                </button>
              </Tooltip>
            </div>

            <div>
              {contact.contact_type === 'Contact' && contact.similar?.length > 0 && (
                <Tooltip label="Upgrade available - A PRO exists with similar contact information." minWidth="200px" placement="bottom">
                  <button
                    className={classNames(
                      'group inline-flex h-12 w-11 items-center justify-center text-orange-500 outline-none transition-all duration-300 hover:text-opacity-75 focus:ring focus:ring-blue-500',
                    )}
                    onClick={onPreview}
                  >
                    <i className="far fa-info-circle text-xl leading-none group-hover:font-bold"></i>
                  </button>
                </Tooltip>
              )}

              {contact.contact_type === 'Contact' &&
                contact.roles?.find((role) => role.party_representing == role.name && role.primary) && (
                  <button className="group group inline-flex h-12 w-11 items-center justify-center text-gray-500 outline-none transition-all duration-300 hover:text-blue-500 focus:ring focus:ring-blue-500">
                    <i className="fas fa-star text-xl leading-none text-orange-500 group-hover:font-bold"></i>
                  </button>
                )}
            </div>
          </div>
        )}
      </div>

      <AddContactDialog contact={contactAdding} open={addDialogOpen} onClose={() => closeAddDialog()} />
    </div>
  )
}
