import { Fragment, useRef, useContext, useEffect, useState } from 'react'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import classNames from 'classnames'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import HeaderColumn from '@/Shared/Table/HeaderColumn'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import PropertyListItem from '@/Shared/PropertyListItem'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import TextArea from '@/Shared/Forms/TextArea'
import { nanoid } from 'nanoid'

export default function Edit({ record, open, onClosed }) {
  let focusRef = useRef()

  const { errors: pageErrors, transaction, auth, media_url } = usePage().props
  const { loading, setLoading } = useContext(BlockUIContext)
  const { data, delete: destroy, setData, errors, clearErrors, setError } = useForm()
  const [check, setCheck] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const administrating = Helpers.isAdminOrHR(auth.user)
  const availableTypes = [{ value: 'Other Income', label: 'Other Income' }]

  useEffect(() => {
    Object.keys(pageErrors).forEach((key) => setError(key, pageErrors[key]))
  }, [pageErrors])

  useEffect(() => {
    if (record) {
      setData({
        ...{ type: '', credit: true, amount: 0, extra: null },
        ...record,
        ...(isIncomePayment()
          ? {
              checks: record.checks ? [...record.checks] : [],
            }
          : {
              paid: record.paid,
            }),
      })
    }
  }, [record])

  const checksReceived = () => {
    return check?.id
      ? data.checks?.reduce(
          (total, checkReceived) => total + (checkReceived.id == check.id ? 0 : Helpers.parseCurrency(checkReceived.amount)),
          0,
        )
      : data.checks?.reduce((total, check) => total + Helpers.parseCurrency(check.amount), 0)
  }

  const amountRemainingToCollect = () => {
    let total = Helpers.parseCurrency(data.amount) + Helpers.parseCurrency(data.extra) - checksReceived()

    return parseFloat(total.toFixed(2))
  }

  const isIncomePayment = () => {
    return !data.credit
  }

  const isCheckFormValid = () => {
    const amount = Helpers.parseCurrency(check.amount)

    if (amount == 0) {
      if (amount == 0) setError('amount', 'This field is required.')
      return false
    } else if (amount > amountRemainingToCollect()) {
      setError('amount', 'Adding this check would exceed the amount remaining to collect.')
      return false
    } else {
      clearErrors('file', 'amount')
      return true
    }
  }

  const onDelete = () => {
    destroy(route(`transactions.payments.destroy`, { transaction: record.transaction_id, payment: record.id }), {
      onSuccess: () => onClosed(),
      onFinish: () => {
        setDeleting(false)
        clearErrors()
        setLoading(false)
      },
    })
  }

  const saveCheck = (event) => {
    event.preventDefault()

    if (!administrating) return

    if (isCheckFormValid()) {
      if (check.id) {
        setData({ ...data, checks: data.checks.map((checkReceived) => (checkReceived.id == check.id ? check : checkReceived)) })
      } else if (!record.id) {
        data.checks.push({ ...check, ...{ id: nanoid() } })
      } else {
        data.checks.push({ ...check })
      }
      setCheck(null)
    }
  }

  const submit = (event) => {
    event.preventDefault()

    if (!administrating) return

    setLoading(true)

    if (data.id) {
      router.post(
        route('transactions.payments.update', { transaction: record.transaction_id, payment: record.id }),
        { ...data, _method: 'put' },
        {
          forceFormData: data.checks?.length > 0,
          onSuccess: () => onClosed(),
          onFinish: () => {
            clearErrors()
            setLoading(false)
          },
        },
      )
    } else {
      router.post(route('transactions.payments.store', { transaction: record.transaction_id }), data, {
        forceFormData: data.checks?.length > 0,
        onSuccess: () => onClosed(),
        onFinish: () => {
          clearErrors()
          setLoading(false)
        },
      })
    }
  }

  if (!record) return <></>

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        administrating && (
          <Button type="submit" theme="solid" form="payment-form" disabled={loading}>
            Save Changes
          </Button>
        )
      }
      footerLeftActions={
        administrating &&
        data.id &&
        data.type === 'Other Income' && (
          <button
            role="button"
            className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
            onClick={() => setDeleting(true)}
          >
            <span className="sr-only">Delete Payment</span>
            <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
          </button>
        )
      }
      show={open}
      size={isIncomePayment() ? 'max-w-3xl' : 'max-w-lg'}
      title={`${data.type} Payment`}
      subTitle="Details"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="payment-form" onSubmit={submit}></form>

      <div className="space-y-6">
        {data.id && !isIncomePayment() && (
          <Fragment>
            <div>
              <Heading>General Information</Heading>

              <div className="-mx-4">
                <PropertyListItem
                  label="Type"
                  value={
                    <span className="text-lg font-bold capitalize">
                      {data.type === 'Referral' && transaction.referral_type + ' '}
                      {data.type}
                    </span>
                  }
                  noBorder
                />
                {record.associate && (
                  <PropertyListItem label="Associate" value={<span className="text-lg font-bold">{record.associate}</span>} />
                )}
                <PropertyListItem
                  label="Amount"
                  value={
                    <span className={classNames('text-lg font-bold', data.credit ? 'text-red-600' : 'text-green-700')}>
                      {Helpers.formatCurrency(data.amount + data.extra, 2)}
                    </span>
                  }
                />

                {data.type === 'Commission' && data.comments && (
                  <PropertyListItem
                    label="Notes"
                    value={
                      <div className="leading-snug">
                        {data.extra && data.type === 'Commission' && (
                          <div>
                            Commission:{' '}
                            {Helpers.formatCurrency(
                              data.amount + (transaction.referral_type === 'internal' ? transaction.referral_fee : 0),
                              2,
                            )}
                          </div>
                        )}
                        {data.comments && <div className="whitespace-pre-wrap">{data.comments}</div>}
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          </Fragment>
        )}

        {data.type && isIncomePayment() && (
          <Fragment>
            <div className="-mx-6 -mt-6 bg-gray-200 p-8">
              <div className="flex overflow-hidden rounded-md border-2 border-blue-500">
                <table className="min-w-full divide-y divide-gray-300/70 bg-white">
                  <thead>
                    <tr>
                      <HeaderColumn noHover noBorder>
                        Type
                      </HeaderColumn>
                      <HeaderColumn className="sm:w-[200px]" noHover>
                        <div className="flex-1 text-center">Amount</div>
                      </HeaderColumn>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr className="group">
                      <td className="py-5 pl-4 sm:pl-6">
                        <div className="font-bold leading-tight text-black">
                          {
                            {
                              [data.type.toLowerCase()]: `${data.type}${data.associate ? ` - ${data.associate}` : ''}`,
                              commission: `Commission - ${data.associate || transaction.type}`,
                              referral: `Referral - ${transaction.referral_type === 'internal' ? data.associate : transaction.referral_name}`,
                            }[data.type.toLowerCase()]
                          }
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-4 py-5 text-center sm:pr-6">
                        {availableTypes.some((type) => type.value === data.type) ? (
                          <TextInput
                            name="amount"
                            classes="mb-0"
                            icon={<i className="fas fa-dollar-sign"></i>}
                            value={data.amount}
                            error={errors.amount}
                            onBlur={() =>
                              setData({
                                ...data,
                                amount: Helpers.formatDecimal(Helpers.parseCurrency(data.amount), 2),
                              })
                            }
                            onChange={(value) =>
                              setData((prevData) => ({
                                ...prevData,
                                amount: Helpers.sanitizeCurrencyInput(value),
                              }))
                            }
                            onFocus={(e) => e.target.select()}
                            clearable
                          />
                        ) : (
                          <span className="font-bold text-green-600">{Helpers.formatCurrency(data.amount + data.extra, 2)}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="pl-4 pr-2 pt-4 sm:pl-6 sm:pr-3" align="right">
                        <div className="font-bold leading-tight text-black">Checks received:</div>
                      </td>

                      <td className="whitespace-nowrap px-4 pt-4 text-center font-medium text-red-600 sm:pr-6">
                        ({Helpers.formatCurrency(checksReceived(), 2)})
                      </td>
                    </tr>

                    <tr>
                      <td className="py-4 pl-4 pr-2  sm:pl-6 sm:pr-3" align="right">
                        <div className="font-bold leading-tight text-black">Amount remaining to collect:</div>
                      </td>

                      <td className="whitespace-nowrap px-4 py-4  text-center sm:pr-6">
                        {Helpers.formatCurrency(amountRemainingToCollect(), 2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="mt-6 px-2">
              <div className="text-2xl font-medium tracking-wider text-gray-800">Checks Received</div>

              <div className="my-4 inline-block min-w-full overflow-hidden rounded-lg px-4 pt-4 align-middle ring-1 ring-gray-300/75">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-3">
                        Check Image
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left font-semibold text-gray-900 sm:table-cell" width="140px">
                        Date Added
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-900" width="100px">
                        Amount
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:w-[100px] sm:pr-3">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.checks?.length > 0 ? (
                      data.checks.map((check, index) => (
                        <tr className="even:bg-gray-50" key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-3">
                            {check.image ? (
                              <div className="relative flex items-center justify-between">
                                <a
                                  href={check.id ? `https://${media_url}/storage/${check.image}` : '#'}
                                  className={classNames(
                                    'flex items-center gap-3 font-semibold outline-none',
                                    check.file?.type === 'application/pdf' || check.extension === 'pdf' ? 'flex items-center gap-1.5' : '',
                                    check.id ? 'focus:rounded-md focus:ring-2 focus:ring-blue-500 focus:ring-offset-4' : 'cursor-default',
                                  )}
                                  target="_blank"
                                  aria-hidden
                                  onClick={(event) => !check.id && event.preventDefault()}
                                >
                                  {(check.file?.type === 'application/pdf' || check.extension === 'pdf') && (
                                    <i className="fas fa-file-pdf text-xl text-gray-500"></i>
                                  )}
                                  {check.file?.name || check.extension === 'pdf' ? (
                                    <span>Image of Check</span>
                                  ) : (
                                    <img className="max-h-[65px]" src={`https://${media_url}/storage/${check.image}`} />
                                  )}
                                  <span
                                    className={classNames(
                                      'text-gray-600',
                                      !check.id || check.file?.type === 'application/pdf' || check.extension === 'pdf' ? '' : 'mt-1',
                                    )}
                                  >
                                    {' '}
                                    ({Helpers.getReadableFileSizeString(check.file?.size || check.size)})
                                  </span>
                                </a>
                              </div>
                            ) : (
                              <span className="text-gray-400">Not supplied</span>
                            )}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-gray-800 sm:table-cell">
                            {check.created_at ||
                              new Date().toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-800">
                            {Helpers.formatCurrency(Helpers.parseCurrency(check.amount), 2)}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-3">
                            {check.image && (
                              <a
                                href={`https://${media_url}/storage/${check.image}`}
                                className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
                                target="_blank"
                              >
                                <span className="sr-only">View File</span>
                                <i className="far fa-arrow-up-right-from-square text-xl text-gray-800 hover:text-blue-500"></i>
                              </a>
                            )}

                            {administrating && (
                              <Fragment>
                                <button
                                  role="button"
                                  className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
                                  onClick={() => setCheck(check)}
                                >
                                  <span className="sr-only">Edit</span>
                                  <i className="far fa-pencil text-xl text-gray-800 group-hover:text-red-600"></i>
                                </button>

                                <button
                                  role="button"
                                  className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
                                  onClick={() => setData({ ...data, checks: data.checks.filter((_, i) => i != index) })}
                                >
                                  <span className="sr-only">Delete</span>
                                  <i className="far fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
                                </button>
                              </Fragment>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="p-4 text-center">
                          <span className="text-lg text-gray-500">No checks have been received for this payment yet.</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {check ? (
                <form id="check-form" className="mt-2 rounded-md border border-gray-300 bg-gray-100 p-6" onSubmit={saveCheck}>
                  <div className="mx-auto max-w-lg space-y-6">
                    <div className="text-xl font-medium tracking-wider text-gray-800">New Check Received</div>

                    <TextInput
                      name="amount"
                      label="Amount"
                      classes="mb-0"
                      icon={<i className="fas fa-dollar-sign"></i>}
                      value={check.amount}
                      error={errors.amount}
                      onBlur={() =>
                        setCheck({
                          ...check,
                          amount: Helpers.formatDecimal(Helpers.parseCurrency(check.amount), 2),
                        })
                      }
                      onChange={(value) =>
                        setCheck((prevData) => ({
                          ...prevData,
                          amount: Helpers.sanitizeCurrencyInput(value),
                        }))
                      }
                      onFocus={(e) => e.target.select()}
                      clearable
                    />

                    <div>
                      {check.file ? (
                        <div className="mt-1">
                          <div className="relative flex items-center justify-between rounded-md bg-gray-200 px-3 py-2">
                            <div
                              className={classNames(
                                'font-semibold',
                                check.file?.type === 'application/pdf' || record?.extension === 'pdf' ? 'flex items-center gap-1.5' : '',
                              )}
                            >
                              {(check.file?.type === 'application/pdf' || record?.extension === 'pdf') && (
                                <i className="fas fa-file-pdf text-xl text-gray-500"></i>
                              )}

                              {check.file.name || record.extension === 'pdf' ? (
                                <span>Image of Check</span>
                              ) : (
                                <img src={`https://${media_url}/storage/${record.image}`} />
                              )}

                              <span
                                className={classNames(
                                  'text-gray-600',
                                  check.file?.type === 'application/pdf' || record?.extension === 'pdf' ? '' : 'mt-1',
                                )}
                              >
                                {' '}
                                ({Helpers.getReadableFileSizeString(check.file.size || record.size)})
                              </span>
                            </div>

                            <div className="flex items-center gap-3">
                              <button
                                type="button"
                                className="flex h-7 items-center justify-center rounded px-2 py-0.5 outline-none hover:bg-red-600 hover:text-white focus:bg-red-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                onClick={() => setCheck({ ...check, file: null })}
                              >
                                <i className="far fa-times text-lg"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <label htmlFor="file" className="text-sm font-medium uppercase text-gray-600">
                            Image of Check - <span className="text-gray-400">Optional</span>
                          </label>

                          <div className="relative">
                            <label
                              className={classNames(
                                'font-md transition-border relative block h-11 w-full rounded bg-white px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out focus-within:border-transparent focus-within:ring-2 focus-within:ring-primary-500 hover:border-gray-400',
                                errors.file ? 'border-2 border-red-600' : 'border border-gray-300',
                              )}
                            >
                              <input
                                type="file"
                                id="file"
                                className="sr-only"
                                accept="image/jpeg,image/png,application/pdf"
                                onChange={(event) => {
                                  clearErrors('file')
                                  setCheck({ ...check, file: Array.from(event.target.files)[0] })
                                }}
                              />
                              Choose File
                              <div className="absolute inset-y-0 right-0 my-px mr-0.5 flex h-[calc(100%-2px)] items-center rounded-r border border-transparent bg-gray-100 px-4 text-gray-700">
                                Browse
                              </div>
                            </label>
                          </div>

                          <div className=" mt-1 text-sm font-medium text-blue-500">JPEG, PNG or PDF accepted</div>

                          {errors.file && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.file }}></div>}
                        </Fragment>
                      )}
                    </div>

                    <div className="relative space-x-2 whitespace-nowrap text-right">
                      <Button
                        type="button"
                        theme="clean"
                        onClick={() => {
                          clearErrors('file', 'amount')
                          setCheck(null)
                        }}
                      >
                        Cancel
                      </Button>

                      <Button type="submit" theme="solid">
                        {check.id ? 'Save' : 'Add'}
                      </Button>
                    </div>
                  </div>
                </form>
              ) : (
                administrating && (
                  <div className="mt-2">
                    <Button theme="border" onClick={() => setCheck({ amount: 0 })}>
                      <i className="far fa-plus pr-1 text-sm"></i>
                      <span>Add Payment</span>
                    </Button>
                  </div>
                )
              )}
            </div>
          </Fragment>
        )}

        {administrating && (
          <div className="space-y-4">
            <Heading>Payment Details</Heading>

            {!isIncomePayment() && (
              <Checkbox
                name="paid"
                label={data.credit ? 'Paid' : 'Received'}
                value={data.paid}
                onChange={(selected) => {
                  setData({ ...data, paid: selected })
                }}
              />
            )}

            <TextArea
              name="comments"
              label="Comments"
              className="block w-full rounded border border-gray-500 px-2 py-1 leading-normal outline-none focus:border-transparent focus:ring-2 focus:ring-blue-500"
              rows={4}
              error={errors.comments}
              value={data.comments}
              onChange={(value) => setData({ ...data, comments: value })}
            />
          </div>
        )}
      </div>

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <Fragment>
          <div className="space-y-6">
            <div className="space-y-1 text-center text-red-600">
              <div className="flex items-center justify-center gap-3">
                <i className="fas fa-exclamation-triangle text-4xl"></i>
                <p className="text-2xl font-semibold">WARNING</p>
              </div>
              <p className="text-lg font-semibold">Are you sure you wish to delete this payment?</p>
            </div>
          </div>
        </Fragment>
      </DeleteConfirmationDialog>
    </SlideOver>
  )
}
