import { Fragment, useEffect } from 'react'
import AmountPercentInput from '@/Shared/Forms/AmountPercentInput'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import Radio from '@/Shared/Forms/Radio'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import TextArea from '@/Shared/Forms/TextArea'
import TimePicker from '@/Shared/TimePicker'
import { useForm, usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

export default ({ step, transaction }) => {
  const { propertyTypes, states } = usePage().props
  const fields = step.settings.fields
  const { data, setData, clearErrors, errors, setError } = useForm()
  const closingWorksheetIsDisabled = transaction.payments.filter((payment) => !payment.credit && payment.paid).length > 0

  useEffect(() => {
    let filledFields = fields.reduce((fields, field) => {
      let filled = {
        [field]: data[field] != undefined ? data[field] : transaction[field] != undefined ? transaction[field] : null,
        closing_report_status: data.closing_report_status || transaction.closing_report_status || 'draft',
        commission_basis: data.commission_basis || transaction.commission_basis || 'net',
        commission_buyer:
          data.commission_buyer || transaction.commission_buyer
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_buyer || transaction.commission_buyer), 2)
            : null,
        commission_buyer_pct:
          data.commission_buyer_pct || transaction.commission_buyer_pct
            ? parseFloat(data.commission_buyer_pct || transaction.commission_buyer_pct)
            : null,
        commission_buyer_additional:
          data.commission_buyer_additional || transaction.commission_buyer_additional
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_buyer_additional || transaction.commission_buyer_additional), 2)
            : null,
        commission_buyer_additional_additional_pct:
          data.commission_buyer_additional_pct || transaction.commission_buyer_additional_pct
            ? parseFloat(data.commission_buyer_additional_pct || transaction.commission_buyer_additional_pct)
            : null,
        commission_seller:
          data.commission_seller || transaction.commission_seller
            ? Helpers.formatDecimal(Helpers.parseCurrency(data.commission_seller || transaction.commission_seller), 2)
            : null,
        commission_seller_pct:
          data.commission_seller_pct || transaction.commission_seller_pct
            ? parseFloat(data.commission_seller_pct || transaction.commission_seller_pct)
            : null,
        property_address: {
          property_type_id:
            data.property_type_id ||
            (transaction.property_type_id ? transaction.property_type_id.toString() : undefined) ||
            (transaction.propertyTypes.length == 1 ? transaction.propertyTypes[0].id.toString() : undefined),
          property_street: data.property_street || transaction.property_street,
          property_street2: data.property_street2 || transaction.property_street2,
          property_city: data.property_city || transaction.property_city,
          property_state: data.property_state || transaction.property_state,
          property_postal_code: data.property_postal_code || transaction.property_postal_code,
        },
        home_inspected_at:
          data.home_inspected_at || transaction.home_inspected_at
            ? new Date(data.home_inspected_at || transaction.home_inspected_at)
            : null,
        home_inspection_contingency_date:
          data.home_inspection_contingency_date || transaction.home_inspection_contingency_date
            ? new Date(data.home_inspection_contingency_date || transaction.home_inspection_contingency_date)
            : null,

        reinspection_date:
          data.reinspection_date || transaction.reinspection_date
            ? new Date(data.reinspection_date || transaction.reinspection_date)
            : null,
        walk_through_date:
          data.walk_through_date || transaction.walk_through_date
            ? new Date(data.walk_through_date || transaction.walk_through_date)
            : null,
      }[field]

      return { ...fields, ...{ [field]: filled } }
    }, [])

    setData({
      ...{ id: transaction.id },
      ...filledFields,
    })
  }, [transaction])

  // computed values
  const purchasePrice = () => {
    let total_purchase_price = data.total_purchase_price || transaction.total_purchase_price
    let closing_cost = data.closing_cost || transaction.closing_cost

    return data.commission_basis === 'net'
      ? Helpers.parseCurrency(total_purchase_price) - Helpers.parseCurrency(closing_cost)
      : Helpers.parseCurrency(total_purchase_price)
  }

  const updateDate = (newDate, currentDate) => {
    if (!newDate) {
      return currentDate
    } else if (!currentDate) {
      newDate.setHours(9)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      return newDate
    }

    let datetime = new Date(newDate)
    datetime.setHours(currentDate.getHours())
    datetime.setMinutes(currentDate.getMinutes())
    datetime.setSeconds(0)

    return datetime
  }

  const submit = (event) => {
    event.preventDefault()

    let transformedData = {
      ...data,
      ...data.property_address,
      home_inspected_at: data.home_inspected_at?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      home_inspection_contingency_date: data.home_inspection_contingency_date?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      reinspection_date: data.reinspection_date?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      walk_through_date: data.walk_through_date?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
    }
    delete transformedData.property_address

    router.post(
      route('transactions.update', data.id),
      {
        ...data,
        ...transformedData,
        _method: 'put',
      },
      {
        onSuccess: (_) => {
          clearErrors()
        },
        onError: (errors) => {
          setError(errors)
        },
      },
    )
  }

  return (
    <div className="mx-auto max-w-lg rounded-md border border-gray-300 bg-white p-4 shadow sm:p-6">
      <form className="space-y-4" onSubmit={submit}>
        {fields.find((field) => field === 'closing_at') && (
          <div className="mb-4">
            <label htmlFor="closing_at" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              Closing Date
            </label>
            <DatePicker
              date={data.closing_at && new Date(data.closing_at)}
              error={errors.closing_at}
              onChange={(date) => setData({ ...data, closing_at: date })}
              disabled={closingWorksheetIsDisabled}
            />
          </div>
        )}

        {fields.find((field) => field === 'closing_report_status') && (
          <div className="mb-4">
            <div className="space-y-4">
              <label className="block text-sm font-medium uppercase text-gray-500">
                <span>Closing Worksheet Status:</span>
              </label>

              <div className="mx-auto max-w-[75%] space-y-4">
                <Radio
                  id="closing_report_status_draft"
                  name="closing_report_status_option"
                  label={<span className="text-xl font-semibold normal-case">Draft / Preliminary</span>}
                  value={data.closing_report_status === 'draft'}
                  onChange={() => setData({ ...data, closing_report_status: 'draft' })}
                />
                <Radio
                  id="closing_report_status_final"
                  name="closing_report_status_option"
                  label={<span className="text-xl font-semibold normal-case">Final</span>}
                  value={data.closing_report_status === 'final'}
                  onChange={() => setData({ ...data, closing_report_status: 'final' })}
                />
              </div>
            </div>
          </div>
        )}

        {fields.find((field) => field === 'closing_cost') && (
          <TextInput
            label={transaction.type === 'Buyer' ? 'Seller Concessions' : 'Closing Cost Credit to Buyer'}
            name="closing_cost"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.closing_cost}
            error={errors.closing_cost}
            onBlur={() =>
              setData({
                ...data,
                closing_cost: Helpers.formatDecimal(Helpers.parseCurrency(data.closing_cost), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                closing_cost: Helpers.sanitizeCurrencyInput(value),
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'total_purchase_price') && (
          <TextInput
            label="Total Purchase Price"
            name="total_purchase_price"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.total_purchase_price}
            error={errors.total_purchase_price}
            onBlur={() =>
              setData({
                ...data,
                total_purchase_price: Helpers.formatDecimal(Helpers.parseCurrency(data.total_purchase_price), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                total_purchase_price: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'escrow_deposit1') && (
          <TextInput
            label="Initial Escrow Deposit"
            name="escrow_deposit1"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.escrow_deposit1}
            error={errors.escrow_deposit1}
            onBlur={() =>
              setData({
                ...data,
                escrow_deposit1: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit1), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                escrow_deposit1: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'escrow_deposit2') && (
          <TextInput
            label="2nd Escrow Deposit"
            name="escrow_deposit2"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.escrow_deposit2}
            error={errors.escrow_deposit2}
            onBlur={() =>
              setData({
                ...data,
                escrow_deposit2: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit2), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                escrow_deposit2: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'commission_basis') && (
          <div className="mb-4 space-y-2">
            <label className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              <span>Commissions based on:</span>
            </label>

            <div className="flex justify-center space-x-4">
              <Radio
                id="commission_basis_gross"
                name="commission_basis"
                label="Gross Purchase Price"
                value={data.commission_basis === 'gross'}
                onChange={() => setData({ ...data, commission_basis: 'gross' })}
                disabled={closingWorksheetIsDisabled}
              />
              <Radio
                id="commission_basis_net"
                name="commission_basis"
                label="Net Purchase Price"
                value={data.commission_basis === 'net'}
                onChange={() => setData({ ...data, commission_basis: 'net' })}
                disabled={closingWorksheetIsDisabled}
              />
            </div>
          </div>
        )}

        {fields.find((field) => field === 'commission_buyer') && (
          <AmountPercentInput
            label="Buyer Agent Compensation"
            name="commission_buyer"
            basis={purchasePrice()}
            value={data.commission_buyer}
            percent={data.commission_buyer_pct}
            error={errors.commission_buyer}
            onChange={(value, percent) => setData((prevData) => ({ ...prevData, commission_buyer: value, commission_buyer_pct: percent }))}
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'commission_buyer_additional') && (
          <AmountPercentInput
            label="Buyer Agent Compensation"
            name="commission_buyer_additional"
            basis={purchasePrice()}
            value={data.commission_buyer_additional}
            percent={data.commission_buyer_additional_pct}
            error={errors.commission_buyer_additional}
            onChange={(value, percent) =>
              setData((prevData) => ({ ...prevData, commission_buyer_additional: value, commission_buyer_additional_pct: percent }))
            }
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'commission_seller') && (
          <AmountPercentInput
            label="Seller Agent Compensation"
            name="commission_seller"
            basis={purchasePrice()}
            value={data.commission_seller}
            percent={data.commission_seller_pct}
            error={errors.commission_seller}
            onChange={(value, percent) =>
              setData((prevData) => ({ ...prevData, commission_seller: value, commission_seller_pct: percent }))
            }
            disabled={!transaction.commission_basis || closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'hiring_date') && (
          <div className="mb-4">
            <label htmlFor="hiring_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              Hiring / Engagement Date
            </label>
            <DatePicker
              date={data.hiring_date && new Date(data.hiring_date)}
              onChange={(date) => setData({ ...data, hiring_date: date })}
            />
          </div>
        )}

        {fields.find((field) => field === 'offer_to_purchase_date') && (
          <div className="mb-4">
            <label htmlFor="offer_to_purchase_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              Offer to Purchase Date
            </label>
            <DatePicker
              date={data.offer_to_purchase_date && new Date(data.offer_to_purchase_date)}
              onChange={(date) => setData({ ...data, offer_to_purchase_date: date })}
              disabled={closingWorksheetIsDisabled}
            />
          </div>
        )}

        {fields.find((field) => field === 'purchase_and_sale_date') && (
          <div className="mb-4">
            <label htmlFor="purchase_and_sale_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              Purchase and Sale Date
            </label>
            <DatePicker
              date={data.purchase_and_sale_date && new Date(data.purchase_and_sale_date)}
              error={errors.purchase_and_sale_date}
              onChange={(date) => setData({ ...data, purchase_and_sale_date: date })}
              disabled={closingWorksheetIsDisabled}
            />
          </div>
        )}

        {fields.find((field) => field === 'home_inspected_at') && (
          <Fragment>
            <div className="mb-4">
              <label htmlFor="home_inspected_at" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                Home Inspection Date
              </label>
              <DatePicker
                date={data.home_inspected_at && new Date(data.home_inspected_at)}
                onChange={(date) => setData({ ...data, home_inspected_at: updateDate(date, data.home_inspected_at) })}
              />
            </div>

            <div className="mb-4">
              <TimePicker
                id="home_inspection_time"
                label="Home Inspection Time"
                value={data.home_inspected_at}
                onChange={(date) => setData({ ...data, home_inspected_at: updateDate(data.home_inspected_at, date) })}
                disabled={!data.home_inspected_at}
                isClearable={true}
              />
            </div>
          </Fragment>
        )}

        {fields.find((field) => field === 'home_inspection_contingency_date') && (
          <Fragment>
            <div className="mb-4">
              <label htmlFor="home_inspection_contingency_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                Home Inspection Contingency Date
              </label>
              <DatePicker
                date={data.home_inspection_contingency_date && new Date(data.home_inspection_contingency_date)}
                onChange={(date) =>
                  setData({ ...data, home_inspection_contingency_date: updateDate(date, data.home_inspection_contingency_date) })
                }
              />
            </div>
          </Fragment>
        )}

        {fields.find((field) => field === 'reinspection_date') && (
          <Fragment>
            <div className="mb-4">
              <label htmlFor="reinspection_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                Reinspection Date
              </label>
              <DatePicker
                date={data.reinspection_date && new Date(data.reinspection_date)}
                onChange={(date) => setData({ ...data, reinspection_date: updateDate(date, data.reinspection_date) })}
              />
            </div>

            <div className="mb-4">
              <TimePicker
                id="reinspection_time"
                label="Reinspection Time"
                value={data.reinspection_date}
                onChange={(date) => setData({ ...data, reinspection_date: updateDate(data.reinspection_date, date) })}
                disabled={!data.reinspection_date}
                isClearable={true}
              />
            </div>
          </Fragment>
        )}

        {fields.find((field) => field === 'mortgage_commitment_date') && (
          <div className="mb-4">
            <label htmlFor="mortgage_commitment_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
              Mortgage Commitment Date
            </label>
            <DatePicker
              date={data.mortgage_commitment_date && new Date(data.mortgage_commitment_date)}
              onChange={(date) => setData({ ...data, mortgage_commitment_date: date })}
            />
          </div>
        )}

        {fields.find((field) => field === 'property_address') && (
          <div>
            <Heading>Property Address</Heading>

            <Select
              error={errors.property_type_id}
              classes="mb-4"
              name="property_state"
              label="Property Type"
              options={propertyTypes.filter((type) => type.active || type.value == transaction.property_type_id)}
              placeholder="Property Type"
              value={data.property_address?.property_type_id}
              isClearable={false}
              onChange={(selected) =>
                setData({
                  ...data,
                  property_address: {
                    ...data.property_address,
                    property_types: transaction.property_type_id ? [selected] : data.property_types,
                    property_type_id: selected?.value,
                  },
                })
              }
              required
            />

            <TextInput
              error={errors.property_street}
              classes="mb-1"
              name="property_street"
              value={data.property_address?.property_street ?? ''}
              placeholder="Street address"
              onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_street: value } })}
              required
            />

            <TextInput
              classes="mb-1"
              name="property_street2"
              value={data.property_address?.property_street2 ?? ''}
              placeholder="Street address line 2"
              onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_street2: value } })}
            />

            <TextInput
              error={errors.property_city}
              classes="mb-1"
              name="property_city"
              value={data.property_address?.property_city ?? ''}
              placeholder="City"
              onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_city: value } })}
            />

            <Select
              error={errors.property_state}
              classes="mb-1"
              name="property_state"
              options={states}
              placeholder="State"
              value={data.property_address?.property_state}
              isClearable={false}
              onChange={(selected) => setData({ ...data, property_address: { ...data.property_address, property_state: selected?.value } })}
            />

            <TextInput
              error={errors.property_postal_code}
              classes="mb-1"
              name="property_postal_code"
              value={data.property_address?.property_postal_code ?? ''}
              placeholder="ZIP code"
              onChange={(value) => setData({ ...data, property_address: { ...data.property_address, property_postal_code: value } })}
            />
          </div>
        )}

        {fields.find((field) => field === 'property_built_year') && (
          <TextInput
            error={errors.property_built_year}
            label="Year Property Built"
            name="property_built_year"
            type="number"
            value={data.property_built_year}
            onChange={(value) => setData({ ...data, property_built_year: value })}
          />
        )}

        {fields.find((field) => field === 'radon_inspection_results') && (
          <TextArea
            label="Radon Inspection Results"
            name="radon_inspection_results"
            value={data.radon_inspection_results}
            error={errors.radon_inspection_results}
            rows="2"
            onChange={(value) => setData({ ...data, radon_inspection_results: value })}
            required
            multiline
          />
        )}

        {fields.find((field) => field === 'walk_through_date') && (
          <Fragment>
            <div className="mb-4">
              <label htmlFor="walk_through_date" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                Walk Through Date
              </label>
              <DatePicker
                date={data.walk_through_date && new Date(data.walk_through_date)}
                onChange={(date) => setData({ ...data, walk_through_date: updateDate(date, data.walk_through_date) })}
              />
            </div>

            <div className="mb-4">
              <TimePicker
                id="walk_through_time"
                label="Walk Through Time"
                value={data.walk_through_date}
                onChange={(date) => setData({ ...data, walk_through_date: updateDate(data.walk_through_date, date) })}
                disabled={!data.walk_through_date}
                isClearable={true}
              />
            </div>
          </Fragment>
        )}

        {fields.find((field) => field === 'smoke_co_detector_inspection_fee') && (
          <TextInput
            label="Fee for Smoke Detector and CO Detector Inspection"
            name="smoke_co_detector_inspection_fee"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.smoke_co_detector_inspection_fee}
            error={errors.smoke_co_detector_inspection_fee}
            onBlur={() =>
              setData({
                ...data,
                smoke_co_detector_inspection_fee: Helpers.formatDecimal(Helpers.parseCurrency(data.smoke_co_detector_inspection_fee), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                smoke_co_detector_inspection_fee: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'water_inspection_fee') && (
          <TextInput
            label="Fee for Final Water Inspection and/or Bill"
            name="water_inspection_fee"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.water_inspection_fee}
            error={errors.water_inspection_fee}
            onBlur={() =>
              setData({
                ...data,
                water_inspection_fee: Helpers.formatDecimal(Helpers.parseCurrency(data.water_inspection_fee), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                water_inspection_fee: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        {fields.find((field) => field === 'other_reimbursements') && (
          <TextInput
            label="Other Reimbursements"
            name="other_reimbursements"
            icon={<i className="fas fa-dollar-sign"></i>}
            value={data.other_reimbursements}
            error={errors.other_reimbursements}
            onBlur={() =>
              setData({
                ...data,
                other_reimbursements: Helpers.formatDecimal(Helpers.parseCurrency(data.other_reimbursements), 2),
              })
            }
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                other_reimbursements: value,
              }))
            }
            onFocus={(e) => e.target.select()}
            clearable
            disabled={closingWorksheetIsDisabled}
          />
        )}

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  )
}
