import { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import classNames from 'classnames'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Heading from '@/Shared/Forms/Heading'
import { Link, useForm } from '@inertiajs/react'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import TimePicker from '@/Shared/TimePicker'
import { router } from '@inertiajs/react'

export default ({ record, open, onClosed }) => {
  let focusRef = useRef()

  const form = useForm({})
  const { data, errors, clearErrors, setError, setData, transform, post, reset } = form
  const listingProviders = [
    { value: 'MLS', label: 'MLS - Property Information Network' },
    { value: 'RILS', label: 'RIAR/State-Wide MLS' },
  ]
  const optionalFields = [
    { name: 'broker_phone', label: 'Broker Phone' },
    { name: 'broker_company', label: 'Broker Company' },
    { name: 'lender_name', label: 'Lender Name' },
    { name: 'lender_company', label: 'Lender Company' },
  ]
  const [confirming, setConfirming] = useState(false)
  const [deletingFile, setDeletingFile] = useState(null)

  useEffect(() => {
    if (open) {
      let openhouse = record || {}
      setData({
        id: openhouse.id || null,
        mls: openhouse.mls || null,
        mls_id: openhouse.mls_id || null,
        address: openhouse.address || null,
        list_price: openhouse.list_price || null,
        image: openhouse.image || '',
        when: openhouse.when || null,
        start: openhouse.start ? new Date(openhouse.start) : null,
        end: openhouse.end ? new Date(openhouse.end) : null,
        cohosts: openhouse.cohosts || null,
        otherAttendees: openhouse.otherAttendees || null,
        checkins_enabled: openhouse.checkins_enabled != undefined ? openhouse.checkins_enabled : false,
        notifications_enabled: openhouse.notifications_enabled != undefined ? openhouse.notifications_enabled : true,
        include_other_attendees_in_emails:
          openhouse.include_other_attendees_in_emails != undefined ? openhouse.include_other_attendees_in_emails : false,
        optional_fields: {
          broker_phone: openhouse.optional_fields?.find((field) => field.name === 'broker_phone') || { visible: false, required: false },
          broker_company: openhouse.optional_fields?.find((field) => field.name === 'broker_company') || {
            visible: false,
            required: false,
          },
          lender_name: openhouse.optional_fields?.find((field) => field.name === 'lender_name') || { visible: false, required: false },
          lender_company: openhouse.optional_fields?.find((field) => field.name === 'lender_company') || {
            visible: false,
            required: false,
          },
        },
        files: {
          feature: openhouse.files?.find((file) => file.type === 'Feature Sheet') || null,
          disclosure: openhouse.files?.find((file) => file.type === 'Seller Disclosure') || null,
          plot: openhouse.files?.find((file) => file.type === 'Plot Plan') || null,
        },
      })
    } else {
      clearErrors()
    }
  }, [open])

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })
  const fetchPROs = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'pros',
      },
    })

  const showOption = (option) => (
    <div className="group flex items-center space-x-3" key={option.id}>
      {option.avatar ? (
        <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
      ) : (
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
          <div className="text-base font-medium tracking-wide">{option.initials}</div>
        </div>
      )}

      <div>
        <div className="space-x-1">
          <span className="space-x-1 font-medium text-gray-900">
            <span>{option.name}</span>
            {option.industry && <span className="text-gray-500">in {option.industry}</span>}
          </span>
        </div>

        {option.company && <div className="text-gray-500">{option.company}</div>}
      </div>
    </div>
  )

  const parseDateTime = (dateString, timeString) => {
    const date = new Date(dateString)
    let hours, minutes
    if (Object.prototype.toString.call(timeString) === '[object Date]') {
      hours = timeString.getHours()
      minutes = timeString.getMinutes() == 0 ? '00' : timeString.getMinutes()
    } else if (timeString != undefined) {
      let [hours, minutes] = timeString.split(':').map(Number)
      minutes = minutes == 0 ? '00' : minutes
    }

    if (hours && minutes) {
      date.setHours(hours, minutes, 0, 0)
    }

    return date
  }

  const onFileChanged = (e, type) => {
    setData({
      ...data,
      files: {
        ...data.files,
        [type]: e.target.files.length == 1 ? { ...data.files[type], filename: e.target.files[0].name, file: e.target.files[0] } : null,
      },
    })
  }

  const onMLSIDChanged = (imageOnly) => {
    if (data.mls && data.mls_id) {
      imageOnly = imageOnly == undefined ? false : imageOnly

      axios
        .get(route('api.mls.show', { type: data.mls, id: data.mls_id }))
        .then((response) => {
          clearErrors()

          if (response?.data) {
            let propertyData = response.data

            let updated = { ...data, image: propertyData.image }
            if (!imageOnly) {
              updated = { ...updated, address: propertyData.address, list_price: propertyData.price }
            }

            setData(updated)
          }
        })
        .catch((error) => {
          let data = error.response.data
          setError(data.errors)
        })
    }
  }

  const resetPhoto = () => {
    onMLSIDChanged(true)
  }

  const onSave = () => {
    setConfirming(false)

    let transformedData = {
      ...data,
      start: data.start?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      end: data.end?.toLocaleString('en-US', { timeZone: 'America/New_York' }),
      cohosts: data.cohosts?.map((a) => a.user_id),
      otherAttendees: data.otherAttendees?.map((m) => m.user_id),
    }

    if (data.id) {
      router.post(
        route('openhouses.update', data.id),
        {
          ...data,
          ...transformedData,
          _method: 'put',
        },
        {
          onSuccess: (_) => {
            clearErrors()
            onClosed()
          },
          onError: (errors) => setError(errors),
        },
      )
    } else {
      router.post(
        route('openhouses.store'),
        {
          ...data,
          ...transformedData,
        },
        {
          onSuccess: (_) => {
            reset()
            onClosed()
          },
          onError: (errors) => setError(errors),
        },
      )
    }
  }

  const submit = (e) => {
    e.preventDefault()

    let requiresConfirmation =
      data.start?.getHours() < 9 || data.start?.getHours() > 21 || data.end?.getHours() < 9 || data.end?.getHours() > 21
    if (requiresConfirmation) {
      setConfirming(true)
    } else {
      onSave()
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="openhouse-form">
          {data.id ? 'Save Changes' : 'Continue'}
        </Button>
      }
      show={open}
      size={data.id ? 'max-w-xl' : 'max-w-lg'}
      title={data.id ? data.address : 'Host an Open House'}
      subTitle={data.id ? 'Open House' : null}
      onClosed={onClosed}
      aboveMessages
    >
      <form id="openhouse-form" className="space-y-6 pb-4" onSubmit={submit}>
        <div>
          <Heading>Listing Details</Heading>

          <Select
            ref={focusRef}
            error={errors.mls}
            label="Listing Provider"
            name="mls"
            options={listingProviders}
            placeholder="Choose one"
            value={data.mls}
            onChange={(selected) => setData({ ...data, mls: selected?.value })}
            isClearable={false}
            required
          />

          <TextInput
            label="MLS #"
            name="mls_id"
            value={data.mls_id}
            error={errors.mls_id}
            onChange={(value) => setData({ ...data, mls_id: value })}
            onBlur={() => onMLSIDChanged()}
            disabled={!data.mls}
            disabledMessage="Please select a Listing Provider"
            required
          />

          <div className="mb-4">
            <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Set or Replace Photo</div>

            <div className="mb-2 mt-1 block rounded-md bg-gray-100 px-3.5 py-2 text-sm normal-case text-gray-500">
              <i className="fas fa-info-circle mr-2 text-blue-400"></i>
              When available, this photo will replace the image imported from MLS.
            </div>

            {data.image?.includes('storage') ? (
              <div className="flex">
                <div className="flex rounded bg-blue-100">
                  <div className="flex border-r border-blue-200/40 font-medium leading-tight">
                    <a
                      className="flex items-center px-3 text-gray-500 hover:rounded-l hover:bg-blue-200/50 hover:text-blue-500 focus:rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                      href={data.image}
                      target="_blank"
                    >
                      <i className="far fa-external-link pr-2 text-gray-600"></i> View Custom Photo
                    </a>
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="px-3.5 py-2 text-black hover:rounded-r hover:bg-red-200 hover:text-red-600 focus:rounded-r focus:bg-red-200 focus:text-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-1"
                      onClick={() => resetPhoto()}
                    >
                      <i className="fas fa-times text-sm"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <input
                name="new_image"
                type="file"
                className="
                block w-full cursor-pointer text-sm text-slate-500 outline-blue-500 file:mr-4
                file:rounded-full file:border-0 file:bg-blue-100
                file:px-4 file:py-2
                file:text-sm file:font-semibold
                file:text-blue-500 hover:file:bg-blue-200
                hover:file:bg-opacity-40
              "
                onChange={(event) => setData({ ...data, new_image: event.target.files[0] })}
              />
            )}
          </div>

          <TextInput
            label="Property Address"
            name="address"
            value={data.address}
            error={errors.address}
            onChange={(value) => setData({ ...data, address: value })}
            required
          />

          <TextInput
            label="List Price"
            name="list_price"
            value={data.list_price}
            error={errors.list_price}
            onChange={(value) => setData({ ...data, list_price: value })}
            required
          />
        </div>

        <div>
          <Heading>Open House Details</Heading>

          <div className="mb-4">
            <label
              htmlFor="when"
              className={classNames('mb-0.5 block text-sm font-medium uppercase', errors.when ? 'text-red-600' : 'text-gray-500')}
            >
              Date <span className="pl-1 text-red-600">*</span>
            </label>

            <DatePicker
              name="when"
              date={data.when && new Date(data.when)}
              error={errors.when}
              onChange={(date) =>
                setData({
                  ...data,
                  when: date,
                  start: data.start && parseDateTime(date, data.start),
                  end: data.end && parseDateTime(date, data.end),
                })
              }
            />

            {errors.when && <div className="mt-1 text-red-600">{errors.when}</div>}
          </div>

          <div className="mb-4">
            <TimePicker
              id="start"
              label="Start Time"
              error={errors.start}
              value={data.start}
              onChange={(date) => setData({ ...data, start: parseDateTime(data.when, date) })}
              required
            />
          </div>

          <div className="mb-4">
            <TimePicker
              id="end"
              label="End Time"
              error={errors.end}
              value={data.end}
              onChange={(date) => setData({ ...data, end: parseDateTime(data.when, date) })}
              required
            />
          </div>

          <Select
            label="Co-Hosts"
            name="cohosts"
            value={data.cohosts}
            optionLabel={(option) => !option.label && showOption(option)}
            optionValue={(option) => option.id}
            placeholder="Search Associates"
            onChange={(selected) => setData({ ...data, cohosts: selected })}
            onInputChanged={(value) => fetchAssociates(value)}
            multiple
            async
          />

          <Select
            label="Other Attendees (i.e. lenders, etc.)"
            name="otherAttendees"
            value={data.otherAttendees}
            optionLabel={(option) => !option.label && showOption(option)}
            optionValue={(option) => option.id}
            placeholder="Search PROs"
            onChange={(selected) => setData({ ...data, otherAttendees: selected })}
            onInputChanged={(value) => fetchPROs(value)}
            multiple
            async
          />

          <Heading>Features</Heading>

          <div className="mb-4">
            <Checkbox
              name="checkins_enabled"
              label="Turn on Check IN & Check OUT Mode"
              value={data.checkins_enabled}
              onChange={(checked) => setData({ ...data, checkins_enabled: checked })}
            />
          </div>

          <div className="mb-4">
            <Checkbox
              name="notifications_enabled"
              label={
                <div className="flex flex-col">
                  <span>Send Notifications to Host/Co-Hosts of New Registrations</span>
                  <div className="mb-2 mt-1 block rounded-md bg-gray-100 px-3.5 py-2 text-sm normal-case text-gray-500">
                    <i className="fas fa-info-circle mr-2 text-blue-400"></i>
                    Open House Notifications can be adjusted in your{' '}
                    <Link href="/account/settings/notifications" className="font-bold underline">
                      Account Settings
                    </Link>
                  </div>
                </div>
              }
              value={data.notifications_enabled}
              onChange={(checked) => setData({ ...data, notifications_enabled: checked })}
            />
          </div>

          <div className="mb-4">
            <Checkbox
              name="include_other_attendees_in_emails"
              label="Include Other Attendees in registration emails"
              value={data.include_other_attendees_in_emails}
              onChange={(checked) => setData({ ...data, include_other_attendees_in_emails: checked })}
            />
          </div>
        </div>
        <div>
          <Heading>Files</Heading>

          <div className="mb-2 mt-1 block rounded-md bg-gray-100 px-3.5 py-2 text-sm normal-case text-gray-500">
            <i className="fas fa-info-circle mr-2 text-blue-400"></i>
            Upload up to three documents that will be attached to the email visitors receive after registering. When adding documents, the
            sum total size of all of your documents added together should be <strong>10MB or less</strong>. Many email services will not
            deliver email with over 10MB of attached files.
          </div>

          <div className="mb-4">
            <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Feature Sheet</div>

            {!data.files?.feature?.id ? (
              <input
                name="feature"
                type="file"
                className="
                    block w-full cursor-pointer text-sm text-slate-500 outline-blue-500 file:mr-4
                    file:rounded-full file:border-0 file:bg-blue-100
                    file:px-4 file:py-2
                    file:text-sm file:font-semibold
                    file:text-blue-500 hover:file:bg-blue-200
                    hover:file:bg-opacity-40
                    focus:file:outline-none focus:file:ring-2 focus:file:ring-inset focus:file:ring-blue-500
                  "
                onChange={(event) => onFileChanged(event, 'feature')}
              />
            ) : (
              <div className="flex">
                <div className="my-1 mr-3 flex rounded bg-blue-100">
                  <div className="flex border-r border-blue-200/40 font-medium leading-tight">
                    <a
                      className="flex items-center px-3 text-gray-500 hover:rounded-l hover:bg-blue-200/50 hover:text-blue-500 focus:rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                      href={route('openhouses.files.show', data.files.feature.id)}
                      target="_blank"
                      key={data.files.feature.id}
                    >
                      <i className="far fa-external-link pr-2 text-gray-600"></i> {data.files.feature.type}
                    </a>
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="px-3.5 py-2 text-black hover:rounded-r hover:bg-red-200 hover:text-red-600 focus:rounded-r focus:bg-red-200 focus:text-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-1"
                      onClick={() => setDeletingFile('feature')}
                    >
                      <i className="fas fa-times text-sm"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Seller Disclosure</div>

            {!data.files?.disclosure?.id ? (
              <input
                name="disclosure"
                type="file"
                className="
                    block w-full cursor-pointer text-sm text-slate-500 outline-blue-500 file:mr-4
                    file:rounded-full file:border-0 file:bg-blue-100
                    file:px-4 file:py-2
                    file:text-sm file:font-semibold
                    file:text-blue-500 hover:file:bg-blue-200
                    hover:file:bg-opacity-40
                    focus:file:outline-none focus:file:ring-2 focus:file:ring-inset focus:file:ring-blue-500
                  "
                onChange={(event) => onFileChanged(event, 'disclosure')}
              />
            ) : (
              <div className="flex">
                <div className="my-1 mr-3 flex rounded bg-blue-100">
                  <div className="flex border-r border-blue-200/40 font-medium leading-tight">
                    <a
                      className="flex items-center px-3 text-gray-500 hover:rounded-l hover:bg-blue-200/50 hover:text-blue-500 focus:rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                      href={route('openhouses.files.show', data.files.disclosure.id)}
                      target="_blank"
                      key={data.files.disclosure.id}
                    >
                      <i className="far fa-external-link pr-2 text-gray-600"></i> {data.files.disclosure.type}
                    </a>
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="px-3.5 py-2 text-black hover:rounded-r hover:bg-red-200 hover:text-red-600 focus:rounded-r focus:bg-red-200 focus:text-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-1"
                      onClick={() => setDeletingFile('disclosure')}
                    >
                      <i className="fas fa-times text-sm"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <div className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Plot Plan</div>

            {!data.files?.plot?.id ? (
              <input
                name="plot"
                type="file"
                className="
                    block w-full cursor-pointer text-sm text-slate-500 outline-blue-500 file:mr-4
                    file:rounded-full file:border-0 file:bg-blue-100
                    file:px-4 file:py-2
                    file:text-sm file:font-semibold
                    file:text-blue-500 hover:file:bg-blue-200
                    hover:file:bg-opacity-40
                    focus:file:outline-none focus:file:ring-2 focus:file:ring-inset focus:file:ring-blue-500
                  "
                onChange={(event) => onFileChanged(event, 'plot')}
              />
            ) : (
              <div className="flex">
                <div className="my-1 mr-3 flex rounded bg-blue-100">
                  <div className="flex border-r border-blue-200/40 font-medium leading-tight">
                    <a
                      className="flex items-center px-3 text-gray-500 hover:rounded-l hover:bg-blue-200/50 hover:text-blue-500 focus:rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                      href={route('openhouses.files.show', data.files.plot.id)}
                      target="_blank"
                      key={data.files.plot.id}
                    >
                      <i className="far fa-external-link pr-2 text-gray-600"></i> {data.files.plot.type}
                    </a>
                  </div>
                  <div className="shrink-0">
                    <button
                      type="button"
                      className="px-3.5 py-2 text-black hover:rounded-r hover:bg-red-200 hover:text-red-600 focus:rounded-r focus:bg-red-200 focus:text-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-1"
                      onClick={() => setDeletingFile('plot')}
                    >
                      <i className="fas fa-times text-sm"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <Heading>Optional Registration Fields</Heading>

          <div className="mb-6 rounded bg-yellow-50 px-6 py-4 shadow">
            <span className="text-gray-700">
              <span className="mr-2 font-semibold text-orange-600">
                <i className="fa fa-warning mr-1"></i> Warning:
              </span>
              <span>
                Required fields can lead to user frustration when registering and is not recommended since not all visitors will have the
                required information.
              </span>
            </span>
          </div>

          <table className="table-collapse w-full border-b border-gray-200 text-left">
            <thead className="border-0 border-b-2 border-t-2 border-gray-300">
              <tr>
                <th className="bg-gray-50 p-2 text-center text-sm font-semibold text-gray-700">Include</th>
                <th className="bg-gray-50 p-2 text-sm font-semibold text-gray-700">Field</th>
                <th className="bg-gray-50 p-2 text-center text-sm font-semibold text-gray-700">Required</th>
              </tr>
            </thead>
            <tbody>
              {data.optional_fields &&
                optionalFields.map((field, index) => (
                  <tr className="border-0" key={index}>
                    <td className="border-t border-gray-300 p-2">
                      <div className="flex items-center justify-center">
                        <Checkbox
                          name={`optional_fields[${field.name}][visible]`}
                          value={data.optional_fields[field.name].visible}
                          onChange={(checked) =>
                            setData({
                              ...data,
                              optional_fields: {
                                ...data.optional_fields,
                                [field.name]: { ...data.optional_fields[field.name], visible: checked },
                              },
                            })
                          }
                        />
                      </div>
                    </td>
                    <td className="border-t border-gray-300 p-2 text-gray-700">
                      <label htmlFor={`${field.name}_visible`} className="pb-0">
                        {field.label}
                      </label>
                    </td>
                    <td className="border-t border-gray-300 p-2">
                      <div className="flex items-center justify-center">
                        <Checkbox
                          name={`optional_fields[${field.name}][required]`}
                          value={data.optional_fields[field.name].required}
                          onChange={(checked) => {
                            setData({
                              ...data,
                              optional_fields: {
                                ...data.optional_fields,
                                [field.name]: { ...data.optional_fields[field.name], required: checked },
                              },
                            })
                          }}
                          disabled={!data.optional_fields[field.name].visible}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </form>

      <ConfirmationDialog
        open={confirming}
        title="Confirm Non-Standard Schedule Times"
        onCanceled={() => setConfirming(false)}
        onApproved={() => onSave()}
      >
        <Fragment>
          <p className="mb-3">
            The time range specified,{' '}
            <span className="text-lg font-medium">
              {data.start?.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: '2-digit' })} ~{' '}
              {data.end?.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: '2-digit' })}
            </span>
            , is outside of the standard time frames for a typical Open House between 9AM ~ 9PM.
          </p>
          <p className="mb-3">Would you like to continue?</p>
        </Fragment>
      </ConfirmationDialog>

      <DeleteConfirmationDialog
        open={deletingFile != null}
        onCanceled={() => setDeletingFile(null)}
        onApproved={() => {
          setData({ ...data, files: { ...data.files, [deletingFile]: null } })
          setDeletingFile(null)
        }}
      >
        <Fragment>
          <p>Are you sure you want to delete this file? This action is permanent and can't be reversed.</p>
        </Fragment>
      </DeleteConfirmationDialog>
    </SlideOver>
  )
}
