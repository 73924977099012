import React, { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import Select from '@/Shared/Forms/Select'
import Steps from '@/Shared/Checklists/Steps'
import Switch from '@/Shared/Forms/Switch'
import '@/Shared/RichTextEditor/styles.scss'
import { usePage } from '@inertiajs/react'
import Helpers from '@/utils/helpers'

export default ({ transaction, completed, onReviewRequested }) => {
  const scrollRef = React.createRef()
  const focusRef = useRef(null)

  const { user } = usePage().props.auth
  const administrating = Helpers.isAdminOrHR(user)

  const [checklist, setChecklist] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)
  const [availableSections, setAvailableSections] = useState([])
  const [showCompleted, setShowCompleted] = useState(false)
  const allStepsCompleted = !transaction.checklist.sections.find((section) => section.steps.find((step) => !Boolean(step.completed_at)))
  const refs = transaction.checklist.sections.reduce((carry, section) => {
    carry[section.id] = React.createRef()
    return carry
  }, {})

  useEffect(() => {
    setChecklist(transaction.checklist)
    setAvailableSections(
      transaction.checklist.sections.map((s) =>
        Object.assign(
          {},
          {
            label: s.name,
            value: s.id,
          },
        ),
      ),
    )
  }, [transaction])

  useEffect(() => {
    if (completed != undefined) {
      setShowCompleted(completed)
    }
  }, [completed])

  useEffect(() => {
    if ((administrating || !transaction.reviewing) && checklist) {
      focusRef.current.focus()
    }
  }, [checklist])

  const scrollToSection = (selected) => {
    setSelectedSection(selected?.value)

    let container = scrollRef.current
    let top = 0

    if (selected?.value && refs[selected.value]?.current) {
      top = refs[selected.value].current.offsetTop - 155
    }

    container.scrollTo({
      behavior: 'smooth',
      top: top,
    })
  }

  const styleSection = (styles, section) => {
    let customStyles = {}
    if (section) {
      let completed = section.steps.filter((s) => Boolean(s.completed_at))

      if (completed.length > 0) {
        customStyles = {
          backgroundColor: completed.length == section.steps.length ? '#e5e7eb' : '#f8eee4',
          color: completed.length == section.steps.length ? '#1f2937' : '#f78e1e',
          ':hover': {
            ...styles[':hover'],
            backgroundColor: '#EDF0FC',
            color: '#000',
          },
        }
      }
    }

    return customStyles
  }

  const onShowCompletedChanged = (selected) => {
    setShowCompleted(selected)
    if (!selected && allStepsCompleted) {
      setSelectedSection(null)
    }
  }

  if (!checklist || !checklist?.sections) {
    return <Fragment></Fragment>
  }

  return (
    <Fragment>
      {(administrating || !transaction.reviewing) && (
        <div className="my-6 hidden items-center justify-center px-6 md:flex lg:my-4 lg:justify-between lg:gap-6">
          <Select
            ref={focusRef}
            classes="flex-1 mt-1 lg:w-2/3"
            options={availableSections}
            optionStyle={{
              option: (styles, { data }) => ({
                ...styles,
                ...styleSection(
                  styles,
                  availableSections.find((s) => s.id == data.value),
                ),
              }),
            }}
            placeholder="All Sections"
            value={selectedSection}
            onChange={(selected) => scrollToSection(selected)}
            disabled={!showCompleted && allStepsCompleted}
          />

          <div className="flex flex-shrink-0 justify-center">
            <Switch
              accessibleText="Show Completed"
              model={showCompleted}
              onChange={(selected) => onShowCompletedChanged(selected)}
              reverseLabel
            >
              <span className="text-sm font-medium uppercase leading-none text-gray-700">Show Completed</span>
            </Switch>
          </div>
        </div>
      )}

      {transaction.checklist.sections.length > 0 && (
        <div ref={scrollRef} className="ProseMirror flex h-full flex-col border-t border-gray-200 lg:overflow-y-auto">
          {transaction.checklist.sections
            .filter((section) => section.steps.filter((step) => showCompleted || !Boolean(step.completed_at)).length > 0)
            .map((section) => (
              <div ref={refs[section.id]} className="mt-0" key={section.id}>
                <div className="flex items-center justify-between bg-blue-500 p-4 sm:px-6">
                  <div className="relative flex-1">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-medium text-white">{section.name}</h3>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 bg-gray-300/20 p-4 md:p-6">
                  <Steps section={section} showCompleted={showCompleted} transaction={transaction} />
                </div>
              </div>
            ))}

          {transaction.editable && !showCompleted && allStepsCompleted && (
            <div className="flex-1 p-4 md:-mt-6 md:flex md:items-center md:justify-center md:bg-gray-300 md:px-0 md:py-0">
              {(administrating || !transaction.reviewing) && (
                <div className="mx-auto w-full max-w-2xl space-y-4 rounded-md bg-white p-8 shadow-md">
                  <div className="text-center text-xl font-medium text-blue-500">All steps for this checklist have been completed</div>

                  {!transaction.reviewing && (
                    <div className="border-t border-gray-200 pt-4">
                      {administrating ? (
                        <p>This transaction is awaiting a request for a compliance review from an eligible Associate.</p>
                      ) : (
                        <Fragment>
                          <div className="space-y-1">
                            <p className="text-lg font-semibold text-black">Ready to mark this transaction as completed?</p>
                            <p>
                              This transaction must be first be reviewed for compliance. Upon approval of the review process, this
                              transaction will be marked as completed.
                            </p>
                          </div>

                          <div className="mt-4 text-center">
                            <Button theme="solid" onClick={() => onReviewRequested()}>
                              Request to Review & Complete Transaction
                            </Button>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}
