import classNames from 'classnames'
import Button from '@/Shared/Button'
import Timeline from '@/Shared/Timeline/Index'
import { usePage } from '@inertiajs/react'

export default ({ open, onBack, onClose }) => {
  const { openhouse } = usePage().props
  return (
    <div
      className={classNames(
        'fixed inset-y-0 right-0 z-30 h-full w-full space-y-6 overflow-y-auto bg-gray-100 transition duration-300 ease-in-out',
        open ? 'translate-x-0' : 'translate-x-full',
      )}
    >
      <div className="sticky top-0 z-20 shadow-md">
        <div className="bg-blue-700 py-2.5 pl-4 pr-3 text-white sm:pl-6">
          <div className="flex items-center justify-between">
            <button className="inline-flex h-10 items-center leading-none text-white outline-none" type="button" onClick={onBack}>
              <span className="sr-only">Back</span>
              <i className="far fa-chevron-left mr-3 text-xl leading-none"></i>
              <span className="font-medium">Menu</span>
            </button>

            <div className="ml-3 flex h-10 items-center">
              <Button theme="toolbar-icon" onClick={onClose}>
                <span className="sr-only">Close</span>
                <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
              </Button>
            </div>
          </div>
        </div>

        <div className="bg-white px-3 py-3 sm:px-6">
          <div className="text-center text-xl">
            <span>{openhouse.address}</span>
          </div>

          <div className="text-center text-2xl font-semibold">
            <span className="text-gray-900">Activity</span>
          </div>
        </div>
      </div>

      <Timeline />
    </div>
  )
}
