export default ({ children, onTabChange }) => {
  return (
    <div className="relative z-0 h-full">
      {children}

      <div className="divide-y divide-gray-300 border-t border-b border-gray-300">
        <button
          className="inline-flex h-20 w-full items-center justify-between bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('about')}
        >
          <div className="text-lg font-semibold text-gray-600">Details</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('visits')}
        >
          <div className="text-lg font-semibold text-gray-600">Visitors</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>

        <button
          className="inline-flex h-20 w-full items-center justify-between bg-gray-50 px-8 py-4 active:bg-gray-200"
          onClick={() => onTabChange('timeline')}
        >
          <div className="text-lg font-semibold text-gray-600">Activity</div>
          <i className="fal fa-angle-right text-2xl font-medium"></i>
        </button>
      </div>
    </div>
  )
}
