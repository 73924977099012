import React, { Fragment, useContext, useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import Portal from '@/Shared/Portal'
import { Transition } from '@headlessui/react'
import { usePage } from '@inertiajs/react'
import { NotificationContext } from '@/Shared/Notifications/NotificationContext'
import { toast } from 'react-toastify'

export default () => {
  const delayRef = useRef()
  const { newNotifications, setNewNotifications } = useContext(NotificationContext)
  const [visible, setVisible] = useState(true)
  const { flash } = usePage().props
  const [message, setMessage] = useState(null)

  useEffect(() => {
    setMessage(flash.message || null)
    setVisible(true)

    delayRef.current = setTimeout(() => {
      clearMessage()
    }, 6000)

    return () => {
      clearTimeout(delayRef.current)
    }
  }, [flash])

  useEffect(() => {
    if (newNotifications > 0) {
      setMessage({
        type: 'info',
        title: `New Notification${newNotifications == 1 ? '' : 's'}`,
        text: `You received ${newNotifications} new notification${newNotifications == 1 ? '' : 's'}.`,
      })

      setNewNotifications(0)
    }
  }, [newNotifications])

  const clearMessage = () => {
    setMessage(null)
    setVisible(false)
  }

  if (!visible || !message) return <Fragment />

  if (message.type === 'error') {
    toast.error(
      <div className="mx-4 flex flex-col gap-6 sm:flex-row sm:items-center">
        <div className="w-full">
          {message.title && <p className="text-lg font-bold">{message.title}</p>}
          {message.text && <p dangerouslySetInnerHTML={{ __html: message.text }}></p>}
        </div>
      </div>,
      {
        toastId: `system-error`,
        position: 'top-center',
        theme: 'colored',
        closeOnClick: true,
        draggable: true,
        progress: 0,
      },
    )

    return <></>
  }

  // Global notification live region, render this permanently at the end of the document
  return (
    <Portal>
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 z-20 flex items-end px-4 py-6 sm:items-end sm:p-6">
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={visible}
            as={Fragment}
            enter="ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'pointer-events-auto w-full max-w-md overflow-hidden rounded-lg border shadow-lg ring-1 ring-black ring-opacity-5',
                {
                  'border-green-400 bg-green-50': !message.type || message.type === 'success',
                  'border-primary-300 bg-primary-100': message.type === 'info',
                  'border-yellow-300 bg-yellow-50': message.type === 'warning',
                  'border-red-300 bg-red-50': message.type === 'error',
                },
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div
                    className={classNames('flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-xl ring-2', {
                      'bg-green-700 ring-green-700': !message.type || message.type === 'success',
                      'bg-primary-500 ring-primary-500': message.type === 'info',
                      'bg-yellow-500 ring-yellow-500': message.type === 'warning',
                      'bg-red-500 ring-red-500': message.type === 'error',
                    })}
                  >
                    <i
                      className={classNames('flex h-6 w-6 items-center justify-center text-xl leading-none text-white', {
                        'fas fa-check-circle': !message.type || message.type === 'success',
                        'fas fa-info-circle': message.type === 'info',
                        'fas fa-exclamation-circle': message.type === 'warning',
                        'fas fa-times-circle': message.type === 'error',
                      })}
                    ></i>
                  </div>
                  <div className="ml-3 w-0 flex-1">
                    {message.title && <p className="text-lg font-medium text-gray-900">{message.title}</p>}
                    {message.text && <p className="mt-1 text-gray-700" dangerouslySetInnerHTML={{ __html: message.text }}></p>}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <Button theme="clean-icon" onClick={() => clearMessage()}>
                      <span className="sr-only">Close</span>
                      <i className="fal fa-times text-2xl leading-none text-gray-500 hover:text-gray-700"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Portal>
  )
}
