import { Fragment, useEffect, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import Edit from './Edit'
import HeaderColumn from '@/Shared/Table/HeaderColumn'
import Helpers from '@/utils/helpers'
import Label from '@/Shared/Label'
import { usePage } from '@inertiajs/react'

const Payments = ({ transaction, type, payments, showAddPaymentButton, rowClicked }) => {
  const { auth } = usePage().props
  const administrating = Helpers.isAdminOrHR(auth.user)

  const totalPaid = (payment) => payment.checks.reduce((total, payment) => total + Helpers.parseCurrency(payment.amount), 0)

  if (payments.length == 0) return <></>

  return (
    <div>
      <div className="mb-2 flex items-center justify-between px-2 py-3">
        <span className="flex-1 text-2xl font-medium tracking-wider text-gray-800">{type}</span>

        {showAddPaymentButton && (
          <Button
            theme="solid"
            onClick={() =>
              rowClicked({
                transaction_id: transaction.id,
                type: 'Other Income',
                credit: false,
              })
            }
          >
            <i className="fas fa-file-invoice-dollar mr-2 text-lg"></i>
            Add Income Payment
          </Button>
        )}
      </div>

      <div className="mb-4 flex flex-grow flex-col divide-y divide-gray-200 rounded bg-white shadow">
        <div className="flex">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <HeaderColumn className="sm:w-auto md:w-[165px] xl:w-auto" noHover noBorder>
                  Type
                </HeaderColumn>

                <HeaderColumn className="sm:w-[120px] md:w-auto xl:w-[120px]" noHover>
                  <div className="flex-1 text-left sm:text-center md:text-left xl:text-center">Amount</div>
                </HeaderColumn>

                {['Incoming', 'Escrow'].some((t) => t === type) && (
                  <Fragment>
                    <HeaderColumn className="hidden sm:table-cell sm:w-[120px] md:hidden xl:table-cell" noHover>
                      <div className="flex-1 text-center">Received</div>
                    </HeaderColumn>
                    <HeaderColumn className="hidden sm:table-cell sm:w-[120px] md:hidden xl:table-cell" noHover>
                      <div className="flex-1 text-center">Remaining</div>
                    </HeaderColumn>
                  </Fragment>
                )}

                <HeaderColumn className="hidden xl:table-cell" width="172px" noHover>
                  <div className="flex-1 text-center">Status</div>
                </HeaderColumn>

                {administrating && (
                  <HeaderColumn className="sm:w-[110px] md:w-auto xl:w-[110px]" noHover noBorder>
                    <span className="sr-only">Actions</span>
                  </HeaderColumn>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {payments.map((payment) => (
                <tr className={classNames({ 'hover:bg-gray-100': administrating || payment.checks.length > 0 }, 'group')} key={payment.id}>
                  <td
                    className={classNames(
                      {
                        'cursor-pointer': administrating || payment.checks.length > 0,
                      },
                      'py-5 pl-4 sm:pl-6',
                    )}
                    onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                  >
                    <div className="font-bold leading-tight text-black">
                      {
                        {
                          [payment.type.toLowerCase()]: `${payment.type}${payment.associate ? ` - ${payment.associate}` : ''}`,
                          commission: `Commission - ${payment.associate || transaction.type}`,
                          referral: `Referral - ${transaction.referral_type === 'internal' ? payment.associate : transaction.referral_name}`,
                        }[payment.type.toLowerCase()]
                      }
                    </div>

                    {Boolean(payment.extra) && payment.type === 'Commission' && (
                      <div className="text-sm font-medium uppercase text-gray-500">
                        Commission:{' '}
                        {Helpers.formatCurrency(
                          payment.amount + (transaction.referral_type === 'internal' ? transaction.referral_fee : 0),
                          2,
                        )}
                      </div>
                    )}

                    {payment.type === 'Referral' && (
                      <div className="text-sm font-medium uppercase text-gray-500">{transaction.referral_type} Referral</div>
                    )}

                    {payment.comments && (
                      <div className="whitespace-pre-wrap text-sm font-medium uppercase leading-tight text-gray-500">
                        {payment.comments}
                        {payment.deducted_from && (
                          <div className="mt-1 flex items-center gap-1 normal-case">
                            <i className="far fa-info-circle text-blue-500"></i>
                            <span className="text-blue-500/75">
                              Deducted from {payment.deducted_from_associate || payment.deducted_from}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="mt-0.5 flex items-center justify-start gap-1.5 xl:hidden">
                      {payment.paid ? <Label type="success">{payment.credit ? 'Paid' : 'Received'}</Label> : <Label>Pending</Label>}
                    </div>
                  </td>

                  <td
                    className={classNames(
                      {
                        'cursor-pointer': administrating || payment.checks.length > 0,
                      },
                      'whitespace-nowrap px-4 py-5 text-left sm:pr-6 sm:text-center md:text-left xl:text-center',
                    )}
                    onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                  >
                    <span className={classNames('font-bold', { 'text-red-600': !['Incoming', 'Escrow'].some((t) => t === type) })}>
                      {Helpers.formatCurrency(payment.amount + payment.extra, 2)}
                    </span>

                    {['Incoming', 'Escrow'].some((t) => t === type) && (
                      <div className="sm:hidden md:block xl:hidden">
                        <div>
                          <span className={classNames('font-bold', payment.credit ? 'text-red-600' : 'text-green-700')}>
                            {Helpers.formatCurrency(totalPaid(payment), 2)}
                          </span>{' '}
                          received
                        </div>

                        <div>
                          <span className="font-bold">
                            {Helpers.formatCurrency(payment.amount + payment.extra - totalPaid(payment), 2)}
                          </span>{' '}
                          remaining
                        </div>
                      </div>
                    )}
                  </td>

                  {['Incoming', 'Escrow'].some((t) => t === type) && (
                    <Fragment>
                      <td
                        className={classNames(
                          {
                            'cursor-pointer': administrating || payment.checks.length > 0,
                          },
                          'hidden whitespace-nowrap px-4 py-5 text-center sm:table-cell sm:pr-6 md:hidden xl:table-cell',
                        )}
                        onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                      >
                        <span className={classNames('font-bold', payment.credit ? 'text-red-600' : 'text-green-700')}>
                          {Helpers.formatCurrency(totalPaid(payment), 2)}
                        </span>
                      </td>

                      <td
                        className={classNames(
                          {
                            'cursor-pointer': administrating || payment.checks.length > 0,
                          },
                          'hidden whitespace-nowrap px-4 py-5 text-center sm:table-cell sm:pr-6 md:hidden xl:table-cell',
                        )}
                        onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                      >
                        <span className={classNames('font-bold')}>
                          {Helpers.formatCurrency(payment.amount + payment.extra - totalPaid(payment), 2)}
                        </span>
                      </td>
                    </Fragment>
                  )}

                  <td
                    className={classNames(
                      {
                        'cursor-pointer': administrating || payment.checks.length > 0,
                      },
                      'hidden whitespace-nowrap px-4 py-5 text-center sm:pr-6 xl:table-cell',
                    )}
                    onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                  >
                    {payment.paid ? <Label type="success">{payment.credit ? 'Paid' : 'Received'}</Label> : <Label>Pending</Label>}
                  </td>

                  {(administrating || payment.checks.length > 0) && (
                    <td className="whitespace-nowrap py-5 pr-4 text-right font-medium sm:pr-6">
                      <div
                        className="flex items-center justify-end gap-4"
                        onClick={() => (administrating || payment.checks.length > 0) && rowClicked(payment)}
                      >
                        {administrating ? (
                          <Button theme="icon-sm">
                            <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                          </Button>
                        ) : (
                          payment.checks.length > 0 && (
                            <Button theme="outline-sm">
                              <div className="flex items-center gap-2">
                                <i className="far fa-magnifying-glass-plus text-xl leading-none"></i>
                                <span className="sm:inline md:hidden xl:inline">View</span>
                              </div>
                            </Button>
                          )
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default function Index() {
  const { transaction } = usePage().props
  const [editing, setEditing] = useState(false)
  const [payment, setPayment] = useState(null)

  const escrowPayments = transaction.payments.filter((payment) => payment.type.includes('Escrow'))
  const incomingBLREPayments = transaction.payments.filter((payment) => !payment.credit && !payment.type.includes('Escrow'))
  const outgoingBLREPayments = transaction.payments.filter((payment) => payment.credit && !payment.type.includes('Escrow'))

  useEffect(() => {
    if (payment != null) {
      setEditing(true)
    }
  }, [payment])

  const rowClicked = (payment) => {
    setPayment(payment)
  }

  return (
    <Fragment>
      {transaction.payments?.length > 0 ? (
        <div className="space-y-10">
          {Boolean(transaction.escrow_held_by_blre) && (
            <div className="rounded-2xl border border-gray-300 bg-blue-100 px-4 py-2 shadow-xl sm:px-6">
              <Payments
                type={
                  <div>
                    <span className="font-bold uppercase">Escrow</span> Deposits
                  </div>
                }
                transaction={transaction}
                payments={escrowPayments.filter((payment) => !payment.credit)}
                rowClicked={(payment) => rowClicked(payment)}
              />

              <Payments
                type="Disbursements"
                transaction={transaction}
                payments={escrowPayments.filter((payment) => payment.credit)}
                rowClicked={(payment) => rowClicked(payment)}
              />
            </div>
          )}

          <Payments
            type="Incoming"
            transaction={transaction}
            payments={incomingBLREPayments}
            rowClicked={(payment) => rowClicked(payment)}
            showAddPaymentButton
          />

          <Payments
            type="Outgoing"
            transaction={transaction}
            payments={outgoingBLREPayments}
            rowClicked={(payment) => rowClicked(payment)}
          />
        </div>
      ) : (
        <div className="rounded-lg bg-white p-4 text-center font-medium text-gray-600 sm:px-6">
          No payments have been added to this transaction.
        </div>
      )}

      <Edit
        record={payment}
        open={editing}
        onClosed={() => {
          setEditing(false)
          setTimeout(() => {
            setPayment(null)
          }, 500)
        }}
      />
    </Fragment>
  )
}
